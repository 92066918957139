import React from 'react';
import { graphql} from 'gatsby';
import Helmet from 'react-helmet';
//import { Parallax } from 'react-scroll-parallax';
import { css } from '@emotion/react'; 
import mq from '@/styles/mq';
import { SEO, Layout, Carousel, HomePresentation, PagePresentation, Services, Fournisseurs } from '@/components';

import { PHomePage } from '@/domain/Homepage';
import { PageContext } from '@/domain/pageContext';

const parallaxOuterStyles = css`
  @media screen and (min-width: 3000px){
    .parallax-outer{
      height: 2100px;
    }
  }
  @media screen and (min-width: 2700px) and (max-width: 3000px){
    .parallax-outer{
      height: 2100px;
    }
  }
  @media screen and (min-width: 2500px) and (max-width: 2700px){
    .parallax-outer{
      height: 2050px;
    }
  }
  @media screen and (min-width: 2300px) and (max-width: 2500px){
    .parallax-outer{
      height: 2000px;
    }
  }
  @media screen and (min-width: 2100px) and (max-width: 2300px){
    .parallax-outer{
      height: 2000px;
    }
  }
  @media screen and (min-width: 1900px) and (max-width: 2100px){
    .parallax-outer{
      height: 1890px;
    }
  }
  @media screen and (min-width: 1700px) and (max-width: 1900px){
    .parallax-outer{
      height: 1850px;
    }
  }
  @media screen and (min-width: 1500px) and (max-width: 1700px){
    .parallax-outer{
      height: 1900px;
    }
  }
  @media screen and (min-width: 1300px) and (max-width: 1500px){
    .parallax-outer{
      height: 1880px;
    }
  }
  @media screen and (min-width: 1100px) and (max-width: 1300px){
    .parallax-outer{
      height: 1900px;
    }
  }
  @media screen and (min-width: 1000px) and (max-width: 1100px){
    .parallax-outer{
      height: 2000px;
    }
  }
  @media screen and (min-width: 900px) and (max-width: 1000px){
    .parallax-outer{
      height: 2100px;
    }
  }
  @media screen and (min-width: 800px) and (max-width: 900px){
    .parallax-outer{
      height: 2170px;
    }
  }
  @media screen and (min-width: 700px) and (max-width: 800px){
    .parallax-outer{
      height: 2150px;
    }
  }
  @media screen and (min-width: 600px) and (max-width: 700px){
    .parallax-outer{
      height: 2150px;
    }
  }
  @media screen and (min-width: 500px) and (max-width: 600px){
    .parallax-outer{
      height: 2400px;
    }
  }
  @media screen and (min-width: 400px) and (max-width: 500px){
    .parallax-outer{
      height: 2500px;
    }
  }
  @media screen and (min-width: 300px) and (max-width: 400px){
    .parallax-outer{
      height: 2770px;
    }
  }
  @media screen and (max-width: 300px){
    .parallax-outer{
      height: 2900px;
    }
  }
`


interface Props{
  data: {
    prismicHomepage: PHomePage;
  }
  pageContext: PageContext;
}


const HomePage = ({ pageContext, data: { prismicHomepage } }: Props): JSX.Element => {

  const { banner, pretitle_presentation1, title_presentation1, button_presentation1, servicesitems } = prismicHomepage.data;
  const { pretitle_presentation2, title_presentation2, content_presentation2, button_presentation2, image_presentation2 } = prismicHomepage.data;
  const { seo_url, seo_title, seo_description, seo_keywords } = prismicHomepage.data;
  const { uid, lang, alternate_languages } = prismicHomepage;
  const { dataHeader, alternate } = pageContext;

  const selectLang = {
    lang: lang,
    uid: uid,
    alternate_languages: alternate_languages
  };

  const homePresentation = {
    preTitle: pretitle_presentation1.raw,
    title: title_presentation1.raw,
    button: button_presentation1.raw,
    servicesitems: servicesitems
  }

  const pagePresentation = {
    preTitle: pretitle_presentation2.raw,
    title: title_presentation2.raw,
    content: content_presentation2.raw,
    button: button_presentation2.raw,
    image: image_presentation2
  }

  const seo = {
    lang: lang,
    url: `${lang.split('-')[0]}/${seo_url.text}`,
    title: seo_title.text,
    description: seo_description.text,
    keywords: seo_keywords.text
  }

  const linkAlternate = alternate?.map(data => ({
    lang: data.lang,
    url: `${data.lang.split('-')[0]}`
  }))
 

  return (
    <Layout header={dataHeader} selectLang={selectLang} isHome={true} >
      <Helmet>
        <script src="https://apps.elfsight.com/p/platform.js" defer></script>
      </Helmet>
      <SEO lang={seo.lang} title={`Global Electrics | ${seo.title}`} url={seo.lang.split('-')[0]} description={seo.description} keywords={seo.keywords} alternate={linkAlternate} />
        <Carousel banner={banner} />
        <div css={parallaxOuterStyles}>
          {/* <Parallax y={[0, -45]} tagOuter="figure"> */}
            <HomePresentation
              data={homePresentation}
            />
            <PagePresentation data={pagePresentation} />
        <div className="elfsight-app-cd5cbd92-dd29-4c78-a57c-b01b1cfde2d0" css={css`
        ${mq.mqMax('lg')}{
          .Foundation__Inner-sc-11tbro4-1{
            max-width: 65%;
          }
        }
        ${mq.mqMax('xsm')}{
          .Foundation__Inner-sc-11tbro4-1{
            max-width: 80%;
          }
        }
        margin-top: 30px; 
        .CommonLayout__Inner-d1flxf-1> a {
          display:none !important;
        }
        `}></div>
            <Services lang={lang} />
            <Fournisseurs lang={lang} />
          {/* </Parallax> */}
        </div>
    </Layout>
  );
};
//Foundation__Inner-sc-11tbro4-1
export const query = graphql`
query ($id: String!) {
  prismicHomepage(id: {eq: $id}) {
    uid
    data {
      banner {
        title {
          raw
        }
        content {
          raw
        }
        button {
          raw
        }
        background_image {
          alt
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
      button_presentation1 {
        raw
      }
      pretitle_presentation1 {
        raw
      }
      title_presentation1 {
        raw
      }
      servicesitems {
        service_title {
          raw
        }
        service_link {
          id
          link_type
          lang
          uid
          type
        }
        service_image {
              fluid {
                aspectRatio
                base64
                sizes
                src
                srcSet
                srcSetWebp
                srcWebp
              }
              alt
        }
      }
      pretitle_presentation2 {
            raw
          }
          title_presentation2 {
            raw
          }
          content_presentation2 {
            raw
          }
          button_presentation2 {
            raw
          }
          image_presentation2 {
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
            alt
          }
          seo_url {
            text
          }
          seo_title {
            text
          }
          seo_description {
            text
          }
          seo_keywords {
            text
          }
    }
    alternate_languages {
      lang
      uid
    }
    lang
  }
}

`

export default HomePage;
